.custom-card-header {
  background-color: rgba(86, 145, 246, .078) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 1.25rem;
  height: 59px;

  h2 {
    margin: 9px 0px;
  }
}
.box-shadow-wrapper {
  padding: 10px 20px;
  border: 1px solid #dee2e6;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.075);
  margin-bottom: 20px;
  border-radius: .3rem;
}
.pointer {
  cursor: pointer !important;
}








.h-20 {
  height: 20px;
}
.h-100 {
  height: 100vh;
}
.mwh-100 {
  max-width: 100px;
}
.mt-20 {
  margin-top: 20px;
}
.p-relative {
  position: relative;
}
.img-btn-avatar {
  position: absolute !important;
  right: 40px !important;
  width: 30px !important;
  height: 30px !important;
}
.img-btn-avatar i {
  line-height: 30px !important;
  font-size: 0.64rem !important;
}
.img-btn-remove {
  position: absolute !important;
  right: 0px !important;
  width: 30px !important;
  height: 30px !important;
}
.img-btn-remove .fa {
  line-height: 30px !important;
  font-size: 0.64rem !important;
}
.characteristic-row .md-form {
  margin-top: 7px;
  margin-bottom: 5px;
}
